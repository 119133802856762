import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';


function Cookie(){
    return(
        <Layout>
            <Helmet>
                <title>Cookies | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="crediflow has a privacy policy and respects your privacy" />
                <meta name="keywords" content="Personal data | privacy policy | cookies" />
                <meta property="og:title" content="crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="crediflow has a privacy policy and respects your privacy" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/cookies" />
                <link rel="cannonical" href="https://www.crediflow.com/cookies" />
            </Helmet>


            <section className="border-t border-gray-100 pt-28">
                    <h1 className="text-center title-font font-semibold text-3xl text-blue-custome">Cookies</h1>
            </section>

            <section className="py-20">
                <div className="container mx-auto px-4 xl:px-24">
                    <p className="text-lg text-gray-700">
                        Crediflow.com uses cookies.
                        <br/><br/>
                        A cookie is a small text file that the website 
                        you visit saves on your computer. Cookies are 
                        used on many websites to give the visitor 
                        access to various functions.
                    </p>

                    <h2 className="mt-10 title-font font-semibold text-3xl  text-gray-800">Personal data</h2>
                    <p className="mt-4 text-lg text-gray-700">
                        Crediflow has a privacy policy and respects your privacy. 
                        When you download or fill in a form from our website, we 
                        need your name, company name, and your e-mail address 
                        so that we can send you more information that you request. 
                        The information we receive from you will only be used 
                        for the above internal purposes. We will under no 
                        circumstances pass on your data to third parties and 
                        under no circumstances would we be allowed to sell, 
                        exchange or lend your data.
                        <br/><br/>
                        When you download something from our website, you 
                        provide your personal data such as name, 
                        telephone, company and e-mail.
                    </p>

                    <h2 className="mt-10 title-font font-semibold text-3xl  text-gray-800">Our purposes with cookies</h2>
                    <p className="mt-4 text-lg text-gray-700 flex">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 mr-4 w-3 h-3 " fill="currentColor" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </span>
                        <span>
                            Collect and analyze behavioral data based on 
                            the use of the website and services in order 
                            to improve the user experience and also enable 
                            personalized communication and messages to the user.
                        </span>
                    </p>
                    <p className="mt-4 text-lg text-gray-700 flex">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 mr-4 w-3 h-3 " fill="currentColor" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </span>
                        <span>
                            Count the number of users and traffic. 
                            By understanding how the website is used, 
                            we can develop and improve the user experience for our visitors.
                        </span>
                    </p>
                    <p className="mt-4 text-lg text-gray-700 flex">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 mr-4 w-3 h-3 " fill="currentColor" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </span>
                        <span>Send relevant information and newsletters.</span>
                    </p>
                </div>
            </section>
        </Layout>
    );
}
export default Cookie;